<template>
  <dx-util-popup
    ref="shipmentItemPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    height="auto"
    :width="resizingWidth"
    :position="'center'"
    @shown="shipmentItemPopupShown"
    @hidden="shipmentItemPopupHidden"
  >
    <div>
      <dx-util-validation-group :key="rerenderKey" :ref="itemFormRef">
        <h4 class="border-bottom mb-1 pb-half text-primary">
          Overview
        </h4>
        <div class="d-flex align-items-center">
          <p-lazy-img width="90px" height="90px" :src="itemFormData.smallImage || defaultImg" />
          <div class="flex-grow-1">
            <dx-util-text-box v-model="itemFormData.ProductTitle" label="Product Title" class="flex-grow-1 mx-1" label-mode="floating">
              <dx-util-validator>
                <dx-util-required-rule message="Title is required" />
              </dx-util-validator>
            </dx-util-text-box>
            <div class="d-flex mx-1 my-half">
              <dx-util-text-box v-model="itemFormData.ManufactureOrSupplier" label="Supplier" label-mode="floating" class="flex-grow-1 mr-1">
                <dx-util-validator>
                  <dx-util-required-rule message="Supplier is required" />
                </dx-util-validator>
              </dx-util-text-box>
              <dx-util-text-box v-model="itemFormData.ProductGroup" label="Product Group" label-mode="floating" class="flex-grow-1 mr-1" />
              <dx-util-select-box v-model="itemFormData.Action" :items="actions" value-expr="value" display-expr="text" label="Item Action" label-mode="floating" class="flex-grow-1 mr-1" />
              <dx-util-select-box v-model="itemFormData.Condition" :items="conditions" value-expr="value" display-expr="text" label="Condition" label-mode="floating" class="flex-grow-1" />
            </div>
          </div>
        </div>
        <h4 class="border-bottom my-1 pb-half text-primary">
          Identifiers
        </h4>
        <div class="d-flex align-items-center">
          <div style="width:90px" />
          <div class="flex-grow-1">
            <div class="d-flex mx-1 my-half">
              <dx-util-text-box v-model="itemFormData.ASIN" label="ASIN" label-mode="floating" class="flex-grow-1 mr-1">
                <dx-util-validator>
                  <dx-util-custom-rule v-if="itemFormData.Action === 'FBA' && itemFormData.MSKU === ''" :validation-callback="checkAsin" message="ASIN is not valid. Please check it from Amazon." />
                  <dx-util-required-rule v-if="itemFormData.Action === 'FBA' && itemFormData.MSKU === ''" message="If action is FBA, you should enter either MSKU or ASIN" />
                </dx-util-validator>
              </dx-util-text-box>
              <dx-util-text-box v-model="itemFormData.MSKU" label="MSKU" label-mode="floating" class="flex-grow-1 mr-1">
                <dx-util-validator>
                  <dx-util-pattern-rule :pattern="mskuPattern" message="MSKU should be valid" />
                  <dx-util-required-rule v-if="itemFormData.Action === 'FBA' && itemFormData.ASIN === ''" message="If action is FBA, you should enter either MSKU or ASIN" />
                </dx-util-validator>
              </dx-util-text-box>
              <dx-util-text-box v-model="itemFormData.UPC" label="UPC" :max-length="14" label-mode="floating" class="flex-grow-1 mr-1">
                <dx-util-validator>
                  <dx-util-pattern-rule :pattern="upcPattern" message="UPC is not valid format. It should be 12 or 13 digits." />
                </dx-util-validator>
              </dx-util-text-box>
              <dx-util-text-box v-model="itemFormData.FNSKU" label="FNSKU" label-mode="floating" class="flex-grow-1 mr-1">
                <!-- <dx-util-validator>
                  <dx-util-pattern-rule :pattern="fnskuPattern" message="FNKSU should start with the letter 'X' followed by exactly 9 capitalized alphanumeric characters." />
                </dx-util-validator> -->
              </dx-util-text-box>
              <dx-util-text-box v-model="itemFormData.SerialNo" label="Serial No" label-mode="floating" class="flex-grow-1">
              </dx-util-text-box>
            </div>
          </div>
        </div>
        <h4 class="border-bottom my-1 pb-half text-primary">
          Tracking
        </h4>
        <div class="d-flex align-items-center">
          <div style="width:90px" />
          <div class="flex-grow-1">
            <div class="d-flex mx-1 my-half">
              <dx-util-text-box v-model="itemFormData.TrackingNumber" label="Tracking No" label-mode="floating" class="w-25 mr-1">
                <dx-util-validator>
                  <dx-util-required-rule v-if="itemFormData.OrderNumber === ''" message="You should enter either tracking number or order number for this item" />
                </dx-util-validator>
              </dx-util-text-box>
              <dx-util-text-box v-model="itemFormData.OrderNumber" label="Order No" label-mode="floating" class="w-25 mr-1">
                <dx-util-validator>
                  <dx-util-required-rule v-if="itemFormData.TrackingNumber === ''" message="You should enter either tracking number or order number for this item" />
                </dx-util-validator>
              </dx-util-text-box>
              <dx-util-text-box v-model="itemFormData.PalletId" label="Pallet ID" label-mode="floating" class="w-25 mr-1" />
            </div>
          </div>
        </div>
        <h4 class="border-bottom my-1 pb-half text-primary">
          Details
        </h4>
        <div class="d-flex align-items-center">
          <div style="width:90px" />
          <div class="flex-grow-1">
            <div class="d-flex mx-1 my-1 align-items-center">
              <dx-util-number-box v-model="itemFormData.Cost" :min="0" :step="0.01" label="Cost" label-mode="floating" :show-spin-buttons="true" class="mr-1" format="$ #,##0.##">
                <dx-util-validator>
                  <dx-util-range-rule :min="0.01" message="Cost should be greater than 0" />
                </dx-util-validator>
              </dx-util-number-box>
              <dx-util-number-box v-model="itemFormData.SalePrice" :min="0" :step="0.01" label="Sale Price" label-mode="floating" :show-spin-buttons="true" class="mr-1" format="$ #,##0.##">
                <dx-util-validator>
                  <dx-util-range-rule :min="0.01" message="Sale price should be greater than 0" />
                </dx-util-validator>
              </dx-util-number-box>
              <dx-util-number-box v-model="itemFormData.NumberOfUnits" :min="0" :show-spin-buttons="true" label="Quantity" label-mode="floating" class="mr-1">
                <dx-util-validator>
                  <dx-util-range-rule :min="0.01" message="Quantity should be greater than 0" />
                </dx-util-validator>
              </dx-util-number-box>
              <dx-util-date-box v-model="itemFormData.ExpirationDate" label="Expiration Date" label-mode="floating" class="mr-1" />
              <dx-util-check-box v-model="isFragile" text="Is Fragile?" @value-changed="onChangeFragile"></dx-util-check-box>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center text-primary">
          <div style="width:90px" />
          <div class="flex-grow-1">
            <div class="d-flex mx-1 my-1 align-items-center">
              <dx-radio-group
                v-model="selectedPacking"
                :data-source="packingOptions"
                layout="horizontal"
                display-expr="text"
                value-expr="value"
                class="mr-2"
                @value-changed="onPackingChange"
              />
              <dx-util-number-box v-if="selectedPacking !== 'single' " v-model="itemFormData.CountPerBundle" :show-spin-buttons="true" label="Count per Bundle/MP" label-mode="floating" class="mr-1" style="width:100px;">
                <dx-util-validator>
                  <dx-util-range-rule :min="2" message="Quantity should be greater than 1" />
                </dx-util-validator>
              </dx-util-number-box>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center" style="height:60px">
          <div style="width:90px" />
          <div class="flex-grow-1 mx-1 my-1">
            <dx-util-text-area v-model="itemFormData.Notes" class="mr-1" label="Notes" label-mode="floating" />
          </div>
        </div>
      </dx-util-validation-group>
    </div>
    <div class="text-right mt-3">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import shipService from '@/http/requests/ship/shipService'
import DxRadioGroup from 'devextreme-vue/radio-group'
import { useWindowSize } from '@vueuse/core'
import { isValidAsin } from '../validationItem'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  emits: ['create-or-update-item', 'cancel-create-or-update-item'],
  components: {
    'dx-radio-group': DxRadioGroup,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    batchId: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup() {
    const { width: windowWidth } = useWindowSize()
    return {
      windowWidth,
    }
  },
  data() {
    return {
      rerenderKey: 0,
      defaultImg: defaultImageUrl,
      itemFormRef: 'itemFormRef',
      popupTitle: '',
      selected: {},
      actions: [],
      // Todo: Conditions have an enums on fba-shipments branch. This variable will be updated.
      conditions: [
        { text: 'New', value: 'NEW' },
        { text: 'Used Like New', value: 'USED_LIKE_NEW' },
        { text: 'Used Very Good', value: 'USED_VERY_GOOD' },
        { text: 'Used Good', value: 'USED_GOOD' },
        { text: 'Used Acceptable', value: 'USED_ACCEPTABLE' },
        { text: 'Collectible Like New', value: 'COLLECTIBLE_LIKE_NEW' },
        { text: 'Collectible Good', value: 'COLLECTIBLE_GOOD' },
        { text: 'Collectible Acceptable', value: 'COLLECTIBLE_ACCEPTABLE' },
        { text: 'Refurbished', value: 'REFURBISHED' },
      ],
      packingOptions: [
        { text: 'Single', value: 'single' },
        { text: 'Bundle', value: 'bundle' },
        { text: 'Multipack', value: 'multipack' },
      ],
      selectedPacking: 'single',
      yesNo: [
        { text: 'Yes', value: 'YES' },
        { text: 'No', value: 'NO' },
      ],
      defaultItemFormData: {},
      itemFormData: {
        id: null,
        batchId: 0,
        ProductTitle: '',
        ManufactureOrSupplier: '',
        ProductGroup: '',
        TrackingNumber: '',
        OrderNumber: '',
        UPC: '',
        ASIN: '',
        MSKU: '',
        FNSKU: '',
        Fragile: 'NO',
        MultiPack: 'NO',
        Bundle: 'NO',
        SerialNo: '',
        CountPerBundle: 0,
        NumberOfUnits: 0,
        Cost: 0,
        SalePrice: 0,
        Action: 'FBA',
        Condition: 'NEW',
        Notes: '',
        PalletId: '',
        ExpirationDate: '',
        smallImage: '',
      },
      currencyOptions: {
        format: '$ #,##0.##',
      },
      numberOptions: {
        min: 0,
        step: 1,
        showSpinButtons: true,
      },
      isValid: true,
      validationErrors: [],
      isEditting: false,
      isFragile: false,
      upcPattern: /^[0-9]{12,13}/,
      fnskuPattern: /^X[A-Z0-9]{9}/,
      mskuPattern: /^(-?\d{1,40}|.{1,40})$/,
      // shadingColor: 'rgba(0, 0, 0, 0.8)',
    }
  },
  computed: {
    shipmentItemPopup() {
      return this.$refs.shipmentItemPopupRef.instance
    },
    resizingWidth() {
      let adjustedWidth = ''
      if (this.windowWidth < 400) {
        adjustedWidth = '95vw'
      } if (this.windowWidth >= 400 && this.windowWidth <= 1200) {
        adjustedWidth = '90vw'
      } if (this.windowWidth > 1200 && this.windowWidth <= 1400) {
        adjustedWidth = '80vw'
      } if (this.windowWidth > 1400) {
        adjustedWidth = '70vw'
      }
      return adjustedWidth
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.rerenderKey += 1
        this.shipmentItemPopup.show()
      },
    },
    id: {
      immediate: true,
      handler(newID) {
        this.validationErrors.splice(0, this.validationErrors.length)
        if (newID && newID !== 0) {
          this.getSelectedShipmentItem(newID)
        }
      },
    },
  },
  mounted() {
    Object.keys(this.$enums.ItemActionTypeEnum).map(key => this.actions.push({
      value: this.$enums.ItemActionTypeEnum[key].value,
      text: this.$enums.ItemActionTypeEnum[key].text,
      disabled: false,
    }))
  },
  methods: {
    shipmentItemPopupShown() {
      this.popupTitle = this.id ? 'Edit Shipping Item' : 'Add Shipping Item'
    },
    shipmentItemPopupHidden() {
      this.resetFormData()
      // const form = this.$refs[this.itemFormRef].instance
      // form.resetValues()
      this.$emit('create-or-update-item')
    },
    closePopup() {
      this.shipmentItemPopup.hide()
    },
    onPackingChange(e) {
      const packing = e.value
      switch (packing) {
        case 'bundle':
          this.itemFormData.MultiPack = 'NO'
          this.itemFormData.Bundle = 'YES'
          break
        case 'multipack':
          this.itemFormData.MultiPack = 'YES'
          this.itemFormData.Bundle = 'NO'
          break
        default:
          this.itemFormData.MultiPack = 'NO'
          this.itemFormData.Bundle = 'NO'
          break
      }
    },
    onChangeFragile(e) {
      const isFragile = e.value
      this.itemFormData.Fragile = isFragile ? 'YES' : 'NO'
    },
    getSelectedShipmentItem(id) {
      shipService.fetchItemById(id).then(result => {
        this.isEditting = true
        this.itemFormData = { ...result.data }
        this.isFragile = this.itemFormData.Fragile === 'YES'

        this.selectedPacking = 'single'
        if (this.itemFormData.MultiPack === 'YES') this.selectedPacking = 'multipack'
        if (this.itemFormData.Bundle === 'YES') this.selectedPacking = 'bundle'
      })
    },
    checkAsin() {
      return isValidAsin(this.itemFormData.ASIN)
    },
    handleSubmit() {
      const form = this.$refs[this.itemFormRef].instance
      const validationResult = form.validate()
      const item = { ...this.itemFormData }
      if (validationResult.isValid) {
        const id = item.id
        item.batchId = this.batchId
        if (id === null) {
          shipService.createItem(item).then(() => {
            this.$emit('create-or-update-item')
            this.$emit('grid-refresh-required')
            this.closePopup()
          })
        } else {
          shipService.updateItem(item).then(() => {
            this.$emit('create-or-update-item')
            this.$emit('grid-refresh-required')
            this.closePopup()
          })
        }
      }
    },
    resetFormData() {
      this.itemFormData = {
        id: null,
        batchId: 0,
        ProductTitle: '',
        ManufactureOrSupplier: '',
        ProductGroup: '',
        TrackingNumber: '',
        OrderNumber: '',
        UPC: '',
        ASIN: '',
        MSKU: '',
        FNSKU: '',
        Fragile: 'NO',
        MultiPack: 'NO',
        Bundle: 'NO',
        SerialNo: '',
        CountPerBundle: 0,
        NumberOfUnits: 0,
        Cost: 0,
        SalePrice: 0,
        Action: 'FBA',
        Condition: 'NEW',
        Notes: '',
        smallImage: '',
      }
    },
  },
}
</script>

<style>

</style>
